/* Slider */
@import 'mixins';
@import 'index-mixins';

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  user-select: none;
  touch-action: pan-y;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}

.slick-slide {
  float: left;
  min-height: 1px;
  [dir="rtl"] & {
    float: right;
  }
  img {
    display: block;
  }
  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-dots {
  padding: 0;
  margin: 24px 0 0;
  text-align: left;

  li {
    list-style: none;
    display: inline-block;
    font-size: 0;

    &.slick-active {
      button {
        opacity: 1;
        cursor: default;
      }
    }

    button {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #212121;
      padding: 0;
      border: 0;
      font-size: 0;
      outline: none;
      cursor: pointer;
      opacity: 0.2;
      transition: opacity 200ms;

      &:hover {
        opacity: 1;
      }
    }
  }

  li + li {
    margin-left: 6px;
  }
}

@include maxMobile {
  .slick-slider {
    margin-right: -$mobileOuterIndent;
    width: 100%;
  }

  .slick-dots {
    margin: 16px 0 0;
  }
}

@include maxTablet {
  .slick-slider {
    margin-right: -$desktopOuterIndent;
  }
}

@include minDesktop {
  .slick-slider {
    margin-right: -$mobileOuterIndent;
  }
  .slick-slider .slick-list {
    margin-left: 0;
    padding-left: 0;
  }
}

