@import 'mixins';

@import 'shadows';

body,
html {
  padding: 0;
  margin: 0;
}

$unit: 4px;
$animationDuration: 200ms;
$gutterWidth: 16px;
